var root;

$(function () {
    // run js if Chrome is being used - fix for the parallel banners for chrome
    if ('WebkitAppearance' in document.documentElement.style) {
        // fix webkit bug with background-attachment:fixed and transform applied, only first banner will be fixed.
        $(".w-slider-mask .banner:not(:first)").css('background-attachment', 'scroll');
    }
    root = $('body').data('root');
    InitPage();  
});

$(window).load(function () {
    $(".portfolioElement, .feature-wrapper, .blogpost-wrapper .p").matchHeight();
    // initialise page
    // lazy load any images
    $("img.lazy").each(function () {
        $(this).attr("src", $(this).attr("data-original")).fadeIn('slow');
        $(this).removeAttr("data-original");
    });
    $('.mbss-listItem2-wrapper').lightGallery({
        selector: '.videoPopBtn',
        loadYoutubeThumbnail: false,
        loadVimeoThumbnail: false
    });
    $('[data-remodal-id=modal]').remodal();

    $.scrollUp({
        scrollImg: true, // Set true to use image
    });
    //add data attributes to banner text
    $('.big-title-banner').data("ix", "fade-in-big-title-on-load");
    $('.sub-text-title').data("ix", "fade-in-sub-text-title-on-load");

    // site search handler - brochure
    $('#site-search-tbx').keypress(function (e) {
        if (e.which == 13) {
            $('#site-search-btn').click();
        }
    });
    $('#site-search-btn').click(function () {
        if ($('#site-search-tbx').val() != "") {
            var str = $('#site-search-tbx').val();
            window.location.href = root + "Home/SiteSearch?s=" + str;
        }
        return false;
    });

    // product search handler
    $("#prod-search-tbx").autocomplete({
        source: root + 'Products/AutoComplete',
        minLength: 2,
        select: function (event, ui) {
            window.location.href = ui.item.url;
        }
    });
    $('#prod-search-tbx').keypress(function (e) {
        if (e.which == 13) {
            $('#prod-search-btn').click();
        }
    });
    $('#prod-search-btn').click(function () {
        if ($('#prod-search-tbx').val() != "") {
            var str = $('#prod-search-tbx').val();
            window.location.href = root + "Products/Search?search=" + encodeURIComponent(str);
        }
        return false;
    });

    // bag handler
    $('#bag-opener').click(function () {
        SlideTopCart();
        return false;
    });

    // login modal trigger
    var loginModal = $('#login-modal').remodal();
    $('.login-modal-btn').click(function () {
        loginModal.open();
    });
    // login from modal form
    $('#login-form-modal').submit(function () {
        $.ajax({
            url: $(this).attr('action'),
            type: $(this).attr('method'),
            dataType: 'json',
            data: $(this).serialize(),
            success: function (data) {
                if (data.result == "ok") {
                    window.location.reload();
                }
                else if (data.result == "fail") {
                    $('#modal-login-warning').fadeIn();
                }
                else {
                    alert("Error: " + data.error);
                }
            },
            error: function (xhr, err) {
                alert('Error: ' + err);
            }
        });
        return false;
    });
});
// webkit load of dom
$(window).load(function () {

    //initialise
    $('.faq-item .mbss-listItem-text-wrapper:nth-child(1) ').append("<div class='w-icon-dropdown-toggle accordiantoggle2'></div>");
    $('.faq-item .mbss-listItem-text-wrapper:nth-child(2)').hide();
    $('.faq-item .mbss-listItem-text-wrapper:nth-child(1)').click(function () {
        var text = $(this).next('div');
        if ($(text).is(':visible')) {
            $(text).slideUp();
        }
        else {
            $(text).slideDown();
        }
    });
});


// functions
function InitPage() {
    $('#top-cart-holder').hide();
    SetCartQty();
    GetTopCart();
}

function GetTopCart() {
    $.post(root + 'Cart/TopCart', null, function (data) {
        $('#top-cart-holder').empty();
        $(data).appendTo('#top-cart-holder');
    });
}

function SlideTopCart() {
    if ($('#top-cart-holder').is(':hidden')) {
        $('#top-cart-holder').slideDown();
    }
    else {
        $('#top-cart-holder').slideUp();
    }
}

function GetAndOpenTopCart() {
    GetTopCart();
    if ($('#top-cart-holder').is(':hidden')) {
        $('#top-cart-holder').slideDown();
    }
}

function SetCartQty() {
    $.post(root + "Cart/GetNumCartItems", function (data) {
        if (data.result == "ok") {
            if (data.qty == 1) {
                $('.cart-total-qty').html(data.qty + " Item");
            }
            else {
                $('.cart-total-qty').html(data.qty + " Items");
            }
        }
    });
}

function RegisterRsp(data) {
    if (data.result == "ok") {
        $('[data-remodal-id=modal]').remodal().open();
        $('#signup-tbx').val('');
    }
    else {
        $(".errorMessage").html(data.result);
        $("#emailError").remodal().open();

    }

}
function emailNotCorrect(data) {
    $('#emailNotCorrect').remodal().open();
}
